@charset "UTF-8";
/*
* BASE DEPENDENCIES
*/
/*
*   ECCO SASS knihovna
*   @version: 3.1.0
*   @author: Štriky
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*
* SHARED
*/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
@keyframes fade-into-foreground {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(1.2);
		opacity: 0;
	}
}

@keyframes fade-into-background {
	from {
		transform: scale(1);
		opacity: 1;
	} to {
		transform: scale(.75);
		opacity: 0;
	}
}

@keyframes pop-into-foreground {
	from {
		transform: scale(.5);
		opacity: 0;
	} to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pop-into-foreground-with-bounce {
	0% {
		transform: scale(.5);
		opacity: 0;
	} 72% {
		transform: scale(1.07);
		opacity: .72;
	} 100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
	} 100% {
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
	}
}
*/
/* autoprefixer: off */
@keyframes slide-from-top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto Slab", Poppins, Futura, Montserrat, sans-serif;
  margin-top: 1.61803em;
}

h1:not(:first-child), h2:not(:first-child), h3:not(:first-child), h4:not(:first-child), h5:not(:first-child), h6:not(:first-child) {
  margin-top: 0;
}

h1, h2, h3, h4 {
  font-weight: 700;
  line-height: 1.15;
  margin: 0 0 .75em 0;
}

h5, h6 {
  font-size: 600;
  margin: 0 0 .5em 0;
}

.main-heading {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: inherit;
  font-weight: 700;
  flex-grow: 0;
  text-align: center;
  padding-top: 1.056em;
}

@media screen and (min-width: 601px) {
  .main-heading {
    text-align: left;
    padding: 1.056em 0;
  }
}

@media screen and (min-width: 941px) {
  .main-heading {
    white-space: nowrap;
  }
}

.main-heading a,
.main-heading a:link,
.main-heading a:visited {
  text-decoration: none;
  color: inherit;
}

h2 {
  font-size: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2.section__heading::before, h2.section__heading::after {
  content: "";
  display: block;
  flex-grow: 1;
  height: 4px;
  background-color: #c80b0b;
}

h2.section__heading::before {
  margin-right: 1em;
}

h2.section__heading::after {
  margin-left: 1em;
}

h3 {
  font-size: 1.875rem;
  font-weight: normal;
}

h4 {
  font-size: 1.625rem;
}

h5 {
  font-size: 1.375rem;
}

h6 {
  font-size: 1.125rem;
}

a,
a:link {
  color: #383433;
}

a:visited {
  color: #023259;
}

a:hover,
a:focus,
a:active {
  color: #c80b0b;
}

._fsr {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

._nls {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

/*
* COMMON BLOCKS
*/
.keyboard-focus:focus {
  outline: 3px skyblue solid;
  outline-offset: 2px;
  -moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/
.input {
  transition: border-color .11s ease, box-shadow .21s ease;
  box-shadow: 0;
  width: 100%;
  font-size: 1em;
  border-radius: 4px;
  border-color: #cccccc;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  color: #2a2a2a;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input:focus {
  border-color: #6b9aeb;
  box-shadow: 0 8px 26px rgba(0, 0, 0, 0.12);
  outline: 0;
}

.input[disabled] {
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #444444;
  cursor: not-allowed;
}

/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/
.hsr {
  position: absolute;
  left: -9999rem;
  overflow: hidden;
  width: 0;
  height: 0;
  outline: 0;
}

.nls {
  list-style-type: none;
}

.centered {
  max-width: 1616px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 601px) {
  .centered {
    width: 92%;
  }
}

.centered.-padded {
  padding-left: 5%;
  padding-right: 5%;
}

.centered.-padded-left {
  padding-left: 5%;
}

.centered.-padded-right {
  padding-right: 5%;
}

@media screen and (min-width: 601px) {
  html {
    padding-bottom: 5em;
  }
}

body {
  overflow-x: hidden;
  color: #242424;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  background-color: #383433;
}

@media screen and (min-width: 601px) {
  body {
    background: #383433 url(/dist/images/bg.jpg) repeat-y 50% 0/100% auto;
  }
}

::selection {
  background-color: #c80b0b;
  color: #ffffff;
}

:focus {
  outline: 0;
}

.content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 5vw;
  padding-right: 5vw;
}

.about-us {
  max-width: 900px;
  font-size: 1.125rem;
  text-align: center;
}

.section {
  padding-top: 10%;
  padding-bottom: 10%;
  position: relative;
}

.section + .section {
  padding-top: 0;
}

@media screen and (min-width: 601px) {
  .section {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .section + .section {
    padding-top: 0;
  }
}

.gallery__list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2.25vw 1em;
  padding: 0;
  justify-content: space-between;
}

.gallery__card {
  flex-basis: 100%;
  flex-grow: 1;
  padding: 2.25vw;
  position: relative;
}

.gallery__card img {
  line-height: 1;
  width: 100%;
  background-color: #cccccc;
}

@media screen and (max-width: 600px) {
  .gallery__card {
    padding-bottom: .5rem;
    padding-top: .5rem;
  }
}

@media screen and (min-width: 601px) {
  .gallery__card {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1201px) {
  .gallery__card {
    padding: 2rem;
  }
}

.gallery__link {
  display: block;
  line-height: 0;
  transition: transform .2s ease-out;
}

.gallery .gallery__link:hover,
.gallery .gallery__link:focus,
.gallery .gallery__link:active {
  transform: scale(1.1);
}

@keyframes appear {
  from {
    transform: scale(0);
    transform-origin: 0 0;
    opacity: 0;
  }
  to {
    transform: scale(1);
    transform-origin: 0 0;
    opacity: 1;
  }
}

.dynamic-list__item {
  animation-name: appear;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  transform: scale(0);
  transform-origin: 0 0;
}

.call-to-action {
  text-align: center;
}

.call-to-action__button {
  background-color: #090909;
  color: #f6f2ef;
  padding: 1em 2em;
  font-size: 1.125rem;
  transition: background-color .18s ease-out;
}

.call-to-action__button:hover, .call-to-action__button:focus {
  color: #c80b0b;
}

.call-to-action a,
.call-to-action a:link,
.call-to-action a:visited {
  color: #f6f2ef;
  text-decoration: none;
}

.call-to-action a:hover,
.call-to-action a:focus,
.call-to-action a:active {
  color: #c80b0b;
  text-decoration: none;
}

.contact {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background-color: rgba(9, 9, 9, 0.7);
  padding: 5% 0;
  position: relative;
}

.contact__heading {
  color: #ffffff;
}

@media screen and (min-width: 601px) {
  .contact {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.contact__section {
  position: relative;
  z-index: 2;
}

.contact__section:first-of-type {
  padding-left: 5vw;
}

.contact__section:nth-child(3) {
  padding-left: 5vw;
}

.contact__section table {
  margin-top: 0;
}

.contact__section table td {
  padding: 0 .5em .75em;
  font-weight: normal;
}

.contact__section table td:first-child {
  padding-left: 0;
  min-width: 2em;
  width: 2em;
}

.contact__section a,
.contact__section a:link,
.contact__section a:visited {
  color: #ffffff;
}

.contact__section a:hover,
.contact__section a:focus,
.contact__section a:active {
  color: #c80b0b;
}

@media screen and (min-width: 601px) {
  .contact__section {
    width: 50%;
  }
}

@media screen and (min-width: 1301px) {
  .contact__section {
    width: 28%;
    flex-shrink: 0;
  }
  .contact__section:nth-child(3) {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
}

.contact__section.-map {
  padding-left: 5vw;
  padding-right: 5vw;
}

@media screen and (min-width: 601px) {
  .contact__section.-map {
    width: 100%;
  }
}

@media screen and (min-width: 1301px) {
  .contact__section.-map {
    padding-left: 0;
    width: 44%;
  }
}

.contact__section.-map iframe {
  width: 100%;
}

.main {
  position: relative;
  box-shadow: 0 11px 98px rgba(0, 0, 0, 0.58);
  background-color: #efefef;
}

@media screen and (min-width: 801px) {
  .main::before {
    content: "";
    background: transparent url(/dist/images/logo.svg) no-repeat 0 0/100%;
    width: 45%;
    height: 0;
    padding-bottom: 50%;
    position: absolute;
    top: 20px;
    left: 5%;
    opacity: .3;
    transform: rotate(-11deg);
    pointer-events: none;
  }
}

.flair {
  position: absolute;
  display: none;
}

@media screen and (min-width: 1801px) {
  .flair {
    display: block;
  }
  .flair.-pepper {
    left: -4%;
    top: -4%;
  }
  .flair.-garlic {
    left: 93%;
    bottom: 0;
  }
  .flair.-steak {
    left: -10%;
    top: 75%;
  }
  .flair.-basil {
    left: 93%;
    top: -2%;
    z-index: 3;
  }
  .flair.-dill {
    left: -6%;
    top: 82%;
  }
  .flair.-chillipepper {
    right: -10%;
    top: 52%;
  }
}

.error-404 {
  padding: 0 5vw;
}

.error-404__flair {
  font-size: 22vw;
}

.header {
  color: #ffffff;
  background-color: #090909;
  position: relative;
  z-index: 2;
  box-shadow: 0 -11px 98px rgba(0, 0, 0, 0.58);
}

.fixed-menu .header {
  z-index: 20;
}

@media screen and (min-width: 601px) {
  .header {
    margin-top: 8vw;
  }
}

@media screen and (min-width: 801px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
}

.header__content {
  padding: .2em 6vw;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 651px) {
  .header__content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 1025px) {
  .header__content::after {
    content: "Řeznictví Kratochvíl";
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Roboto Slab", Poppins, Futura, Montserrat, sans-serif;
    flex-grow: 0;
    color: transparent;
  }
}

.attention {
  padding: 1em;
  display: inline-block;
  background-color: #c80b0b;
  color: #ffffff;
  text-align: left;
  margin-bottom: 1.5em;
}

.attention:empty {
  display: none;
}

.attention ::selection {
  background-color: #090909;
}

.attention p {
  margin: 0;
}

.hero {
  padding: 4vw 6vw;
  text-align: left;
}

@media screen and (min-width: 601px) {
  .hero {
    background: transparent url(/dist/images/hero.jpg) no-repeat 50% 50%/cover;
  }
  .hero__texts {
    padding-right: 50%;
  }
}

.hero__text {
  font-family: "Roboto Slab", Poppins, Futura, Montserrat, sans-serif;
  text-align: left;
  font-weight: bold;
  line-height: 1;
}

.hero__text.-small {
  font-size: 1.0625rem;
  margin: 1em 0;
}

@media screen and (min-width: 801px) {
  .hero__text.-small {
    font-size: 1.75vw;
  }
}

@media screen and (min-width: 1617px) {
  .hero__text.-small {
    font-size: 1.25rem;
  }
}

.hero__text.-massive {
  font-size: 7.25vw;
  margin: 0;
}

@media screen and (min-width: 1617px) {
  .hero__text.-massive {
    font-size: 6.25rem;
  }
}

.page-footer {
  text-align: center;
  padding-top: 3em;
  padding-bottom: 3em;
  text-transform: lowercase;
  color: #ffffff;
}

.page-footer a,
.page-footer a:link,
.page-footer a:visited {
  color: #ffffff;
  text-decoration: underline;
}

.page-footer a:hover,
.page-footer a:focus,
.page-footer a:active {
  text-decoration: none;
}

/*
* DESIGN COMPONENTS
*/
.button {
  border-radius: 4px;
  padding: 5px 2em;
  margin: 0;
  min-height: 42px;
  background-color: #c80b0b;
  color: #f6f2ef;
  text-align: center;
  transition: background-color .15s ease, box-shadow .15s ease;
  position: relative;
}

.button:not([disabled]):hover, .button:not([disabled]):focus, .button:not([disabled]):active {
  z-index: 3;
  outline: 0;
  box-shadow: 0 5px 20px #c80b0b;
}

.button:not([disabled]):hover.-inverted, .button:not([disabled]):focus.-inverted, .button:not([disabled]):active.-inverted {
  box-shadow: 0 5px 20px #f6f2ef;
}

.button[disabled] {
  color: #999999;
  background-color: #ebebeb;
  cursor: not-allowed;
}

.button.-icon::before {
  vertical-align: middle;
  margin-right: .75em;
  display: inline-block;
}

.button.-block {
  display: block;
  width: 100%;
}

.button.-large {
  font-size: 1.15rem;
  padding: 1em 3em;
}

.button.-inverted {
  background-color: #f6f2ef;
  color: #c80b0b;
}

.button.-primary {
  background-color: #c80b0b;
  box-shadow: 0 0 0 0 #c80b0b;
  color: #f6f2ef;
}

.button.-primary.-inverted {
  background-color: #f6f2ef;
  box-shadow: 0 0 0 0 #f6f2ef;
  color: #c80b0b;
}

.button.-primary:not([disabled]):hover, .button.-primary:not([disabled]):focus, .button.-primary:not([disabled]):active {
  box-shadow: 0 5px 20px #c80b0b;
}

.button.-primary:not([disabled]):hover.-inverted, .button.-primary:not([disabled]):focus.-inverted, .button.-primary:not([disabled]):active.-inverted {
  box-shadow: 0 5px 20px #f6f2ef;
}

.input {
  min-height: 42px;
  padding: .3em 1em;
}

.input.-main {
  min-height: 54px;
  font-size: 1.15em;
}

.form {
  padding: 2em 0;
  margin: 0 0 2em 0;
}

.form__fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.form__fields > :not(.form__section) {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.form__section {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  flex-basis: 50%;
  padding: 0 1.25rem;
  min-width: 18em;
  margin-bottom: 1.1em;
}

.form__section.-full {
  width: 100%;
  flex-basis: 100%;
}

.form__section.-to-end {
  justify-content: flex-end;
}

.form__section.-to-center, .form__section.-to-right {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1;
  width: 100%;
}

.form__section.-to-center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form__section.-to-right {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.form__section .label {
  margin-bottom: .6em;
}

.form__section .button:nth-last-child(n+2), .form__section .button:nth-last-child(n+2) ~ .button {
  margin-bottom: .5em;
}

.form__section .button:not(:first-child):not(.-block) {
  margin-left: .5em;
}

.form__label {
  display: block;
  margin-bottom: .4em;
  cursor: default;
}

.form__file-value {
  color: inherit;
  opacity: .8;
}

.form__fieldset {
  padding: .85em 1.5em .625em;
  border: 1px #cccccc solid;
  border-radius: 4px;
}

.form__legend {
  padding: 0 .5em;
  font-size: 1.35rem;
  font-weight: bold;
}

.search-form {
  padding-top: 0;
  padding-bottom: 0;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  margin: 0 -2.25vw 3em;
}

.grid__item {
  flex-grow: 0;
  padding: 2.25vw;
}

.grid__item.-fl {
  display: flex;
  flex-direction: column;
}

.grid__item.-fl > :only-child {
  flex-grow: 1;
}

@media screen and (max-width: 649px) {
  .grid.-halves .grid__item {
    width: 100%;
  }
}

@media screen and (min-width: 650px) {
  .grid.-halves .grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 499px) {
  .grid.-thirds .grid__item {
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
  .grid.-thirds .grid__item {
    width: 50%;
  }
}

@media screen and (min-width: 800px) {
  .grid.-thirds .grid__item {
    width: 33.33333%;
  }
}

@media screen and (min-width: 801px) {
  .grid.-thirds {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-thirds .grid__item {
    padding: 1.6875vw;
    line-height: 1.53713;
  }
}

@media screen and (max-width: 599px) {
  .grid.-quarters .grid__item {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .grid.-quarters .grid__item {
    width: 50%;
  }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  .grid.-quarters .grid__item {
    width: 33.33333%;
  }
}

@media screen and (min-width: 1024px) {
  .grid.-quarters .grid__item {
    width: 25%;
  }
}

@media screen and (min-width: 801px) {
  .grid.-quarters {
    margin-left: -1.6875vw;
    margin-right: -1.6875vw;
  }
  .grid.-quarters .grid__item {
    padding: 1.6875vw;
    line-height: 1.53713;
  }
}

@media screen and (min-width: 1025px) {
  .grid.-quarters {
    margin-left: -1.125vw;
    margin-right: -1.125vw;
  }
  .grid.-quarters .grid__item {
    padding: 1.125vw;
    line-height: 1.37533;
  }
}

.main-nav {
  flex-shrink: 0;
  color: #ffffff;
  padding: 1em 0;
  /* autoprefixer: off */
}

@media screen and (min-width: 1025px) {
  .main-nav {
    flex-grow: 1;
  }
}

.main-nav__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media screen and (min-width: 401px) {
  .main-nav__list {
    flex-direction: row;
  }
}

@media screen and (min-width: 1025px) {
  .main-nav__list {
    margin: 0 0 0 20px;
  }
}

@media screen and (min-width: 1025px) {
  .main-nav__list {
    margin: 0 20px;
  }
}

.main-nav__item {
  display: inline-block;
  margin: 10px 14px;
  text-align: center;
}

@media screen and (min-width: 401px) {
  .main-nav__item:first-child {
    margin-left: 0;
  }
  .main-nav__item:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 801px) {
  .main-nav__item {
    margin: 10px 24px;
  }
}

.main-nav__link {
  padding: 0;
}

.main-nav__link, .main-nav__link:link, .main-nav__link:visited {
  color: #ffffff;
  text-decoration: none;
}

.main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
  color: #c80b0b;
}

.main-nav__link.-active {
  text-decoration: none;
  cursor: default;
  pointer-events: none;
  color: #c80b0b;
}

.fixed-menu .main-nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  animation: slide-from-top .3s ease-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  background-color: #181818;
}

.card {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.card__image {
  margin: 1em 0;
  width: 100%;
}

.card__heading {
  display: block;
  text-decoration: none;
  background-color: #090909;
  margin-top: auto;
  transition: background-color .16s ease-out;
  text-align: center;
  margin: auto 0 0;
  padding: 1.1em 1.5em 1.25em;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #ffffff;
  flex-grow: 1;
}

.card__content {
  margin: 0;
}
