
body {
	font-family: $font-body;
}

@include headings(1, 6) {
	font-family: $font-headings;
	margin-top: to-length(const("GOLDEN_RATIO"), "em");
	
	&:not(:first-child) {
		margin-top: 0;
	}
}

@include headings(1, 4) {
	font-weight: 700;
	line-height: 1.15;
	margin: 0 0 .75em 0;
}

@include headings(5, 6) {
	font-size: 600;
	margin: 0 0 .5em 0;
}

.main-heading {
	font-size: 1.5rem;
	margin-bottom: 0;
	color: inherit;
	font-weight: 700;
	flex-grow: 0;
	text-align: center;
	padding-top: 1.056em;

	@include bp("> 600px") {
		text-align: left;
		padding: 1.056em 0;
	}
	
	@include bp("> 940px") {
		white-space: nowrap;
	}

	@include link {
		text-decoration: none;
		color: inherit;
	}
}

h2 {
	font-size: 2.25rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&.section__heading {
		&::before,
		&::after {
			content: "";
			display: block;
			flex-grow: 1;
			height: 4px;
			background-color: $color-brand;
		}

		&::before {
			margin-right: 1em;
		}

		&::after {
			margin-left: 1em;
		}
	}
}

h3 {
	font-size: 1.875rem;
	font-weight: normal;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

@include link($visited: false) {
	color: $color-dark;
}

@include link-visited {
	color: lighten(#011627, 10%);
}

@include link-over {
	color: $color-brand;
}