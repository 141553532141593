
%default-outline {	
	outline: 3px skyblue solid;
	outline-offset: 2px;
	-moz-outline-radius: 3px;
}

/*==============================
	INPUT, TEXTAREA, SELECT
==============================*/

%form-element-template {
   transition: border-color .11s ease, box-shadow .21s ease;
	box-shadow: 0;
	width: 100%;
	font-size: 1em;
	border-radius: $form-item-border-radius;
	border-color: $form-item-border-color;
	border-style: solid;
	border-width: $form-item-border-width;
	background-color: $form-item-bg;
	color: $form-item-fg;
	background-image: none;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;

	&:focus {
		border-color: $form-active-color;
		box-shadow: 0 8px 26px $form-item-focus-shadow;
		outline: 0;
	}

	&[disabled] {
		border-color: $form-disabled-item-border-color;
		background-color: $form-disabled-item-bg;
		color: $form-disabled-item-fg;
		cursor: not-allowed;
	}
}


/*==============================
	CHECKBOX, SWITCH, RADIO
==============================*/

%form-element-switchable-template {
   margin-bottom: .6em;
	position: relative;

	.input {
		position: absolute;
		left: -9999rem;
		top: auto;
	}
}

%form-element-switchable-label-template {
	display: flex;
	align-items: center;
   word-wrap: break-word;
   word-break: break-all;
   position: relative;
   touch-action: manipulation;
}
