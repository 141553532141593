
.header {
	color: #ffffff;
	background-color: $color-complementary;
	position: relative;
	z-index: 2;
	box-shadow: 0 -11px 98px rgba(#000000, .58);

	.fixed-menu & {
		z-index: 20;
	}

	@include bp("> 600px") {
		margin-top: 8vw;
	}

	@include bp("> 800px") {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: center;
	}

	&__content {
		padding: .2em 6vw;
		display: flex;
		flex-direction: column;

		@include bp("> 650px") {
			flex-direction: row;
			justify-content: space-between;
			align-items: center; 
		}

		@include bp("> 1024px") {
			&::after { //filler
				content: "Řeznictví Kratochvíl";
				font-size: 1.5rem;
				font-weight: bold;
				font-family: $font-headings;
				flex-grow: 0;
				color: transparent;
			}
		}
	}
}

.attention {
	padding: 1em;
	display: inline-block;
	background-color: $color-brand;
	color: #ffffff;
	text-align: left;
	margin-bottom: 1.5em;

	&:empty {
		display: none;
	}

	::selection {
		background-color: $color-complementary;	
	}

	p {
		margin: 0;
	}
}

.hero {
	padding: 4vw 6vw;
	text-align: left;

	@include bp("> 600px") {
		background: transparent url(/dist/images/hero.jpg) no-repeat 50% 50% / cover;

		&__texts {
			padding-right: 50%;
		}
	}

	&__text {
		font-family: $font-headings;
		text-align: left;
		font-weight: bold;
		line-height: 1;

		&.-small {
			font-size: rem(17px);
			margin: 1em 0;

			@include bp("> 800px") {
				font-size: 1.75vw;
			}

			@include bp("> #{$max-width}") {
				font-size: rem(20px);
			}
		}
		
		&.-massive {
			font-size: 7.25vw;
			margin: 0;

			@include bp("> #{$max-width}") {
				font-size: rem(100px);
			}
		}
	}
}