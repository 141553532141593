

.card {
	background-color: #ffffff;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&__image {
		margin: 1em 0;
		width: 100%;
	}

	&__heading {
		display: block;
		text-decoration: none;
		background-color: $color-complementary;
		margin-top: auto;
		transition: background-color .16s ease-out;
		text-align: center;
		margin: auto 0 0;
		padding: 1.1em 1.5em 1.25em;
		font-family: $font-body;
		font-size: rem(16px);
		color: #ffffff;
		flex-grow: 1;
	}

	&__content {
		margin: 0;
	}
}