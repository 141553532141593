
$_ECCO_CONSTANTS: (
   "GOLDEN_RATIO" : 1.618033988749
) !global;

@function const($name) {
   @if not map-has-key($_ECCO_CONSTANTS, $name) {
      @error 'Neznama konstanta `#{$name}`.';
   }

   @return map-get($_ECCO_CONSTANTS, $name);
}