
.page-footer {
	text-align: center;
	padding-top: 3em;
	padding-bottom: 3em;
	text-transform: lowercase;
	color: #ffffff;

	@include link {
		color: #ffffff;
		text-decoration: underline;
	}
	
	@include link-over {
		text-decoration: none;
	}
}
