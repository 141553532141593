
.grid {
   display: flex;
   flex-wrap: wrap;
   padding-left: 0;
   padding-right: 0;
   margin: 0 #{-$grid-spacing} 3em;

   &__item {
      flex-grow: 0;
      padding: $grid-spacing;

      &.-fl {
         display: flex;
         flex-direction: column;

         > :only-child {
            flex-grow: 1;
         }
      }
   }

   &.-halves {
      .grid__item {
         @include grid(650px 2);
      }
   }

   &.-thirds {
      .grid__item {
         @include grid(500px 2, 800px 3);
      }

      @include bp("> 800px") {   
         margin-left: -$grid-spacing*.75;
         margin-right: -$grid-spacing*.75;

         .grid__item {
            padding: $grid-spacing*.75;
            line-height: const(GOLDEN_RATIO) * .95;
         }
      }
   }
   
   &.-quarters {
      .grid__item {
         @include grid(600px 2, 800px 3, 1024px 4);
      }
      
      @include bp("> 800px") {   
         margin-left: -$grid-spacing*.75;
         margin-right: -$grid-spacing*.75;

         .grid__item {
            padding: $grid-spacing*.75;
            line-height: const(GOLDEN_RATIO) * .95;
         }
      }
      
      @include bp("> 1024px") {
         margin-left: -$grid-spacing*.5;
         margin-right: -$grid-spacing*.5;

         .grid__item {
            padding: $grid-spacing*.5;
            line-height: const(GOLDEN_RATIO) * .85;
         }
      }
   }
}