
html {
	@include bp("> 600px") {
		padding-bottom: 5em;
	}
} 

body {
	overflow-x: hidden;
	color: $color-base;
	font-family: $font-body;
	background-color: $color-dark;

	@include bp("> 600px") {
		background: $color-dark url(/dist/images/bg.jpg) repeat-y 50% 0 / 100% auto;
	}
}

::selection {
	background-color: $color-brand;
	color: #ffffff;
}

:focus {
	outline: 0;
}

.keyboard-focus:focus {
	@extend %default-outline;
}

.content {
	margin-left: auto;
	margin-right: auto;
	padding-left: 5vw;
	padding-right: 5vw;
}

.about-us {
	max-width: 900px;
	font-size: rem(18px);
	text-align: center;
}

.section {
	padding-top: 10%;
	padding-bottom: 10%;
	position: relative;

	+ .section {
		padding-top: 0;
	}

	@include bp("> 600px") {
		padding-top: 5%;
		padding-bottom: 5%;

		+ .section {
			padding-top: 0;
		}
	}
}


.gallery {
	&__list {
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -#{$grid-spacing} 1em;
		padding: 0;
		justify-content: space-between;
	}

	&__card {
		flex-basis: 100%;
		flex-grow: 1;
		padding: $grid-spacing;
		position: relative;

		img {
			line-height: 1;
			width: 100%;
			background-color: #cccccc;
		}

		@include bp("<= 600px") {
			padding-bottom: .5rem;
			padding-top: .5rem;
		}
		
		@include bp("> 600px") {
			flex-basis: 50%;
			max-width: 50%;
		}
		
		@include bp("> 1200px") {
			padding: 2rem;
		}
	}

	&__link {
		display: block;
		line-height: 0;
		transition: transform .2s ease-out;
	}

	@include link-over(".gallery__link") {
		transform: scale(1.1);
	}
}

@keyframes appear {
	from {
		transform: scale(0);
		transform-origin: 0 0;
		opacity: 0;
	} to {
		transform: scale(1);
		transform-origin: 0 0;
		opacity: 1;
	}
}

.dynamic-list {
	&__item {
		animation-name: appear;
		animation-duration: .2s;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		transform: scale(0);
		transform-origin: 0 0;
	}
}

.call-to-action {
	text-align: center;

	&__button {
		background-color: $color-complementary;
		color: $color-light;
		padding: 1em 2em;
		font-size: rem(18px);
		transition: background-color .18s ease-out;

		&:hover,
		&:focus {
			color: $color-brand;
		}
	}

	@include link {
		color: $color-light;
		text-decoration: none;
	}
	
	@include link-over {
		color: $color-brand;
		text-decoration: none;
	}
}

.contact {
	display: flex;
	flex-direction: column;
	color: #ffffff;
	background-color: rgba($color-complementary, .7);
	padding: 5% 0;
	position: relative;

	&__heading {
		color: #ffffff;
	}

	@include bp("> 600px") {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__section {
		position: relative;
		z-index: 2;

		&:first-of-type {
			padding-left: 5vw;
		}

		&:nth-child(3) {
			padding-left: 5vw;
		}

		table {
			margin-top: 0;

			td {
				padding: 0 .5em .75em;
				font-weight: normal;
			}

			td:first-child {
				padding-left: 0;
				min-width: 2em;
				width: 2em;
			}
		}

		@include link {
			color: #ffffff;
		}
		
		@include link-over {
			color: $color-brand;
		}

		@include bp("> 600px") {
			width: 50%;
		}

		@include bp("> 1300px") {
			width: 28%;
			flex-shrink: 0;

			&:nth-child(3) {
				padding-left: 2.5vw;
				padding-right: 2.5vw;
			}
		}

		&.-map {
			padding-left: 5vw;
			padding-right: 5vw;

			@include bp("> 600px") {
				width: 100%;
			}

			@include bp("> 1300px") {
				padding-left: 0;
				width: 44%;
			}

			iframe {
				width: 100%;
			}
		}
	}
}

.main {
	position: relative;
	box-shadow: 0 11px 98px rgba(#000000, .58);
	background-color: $color-secondary;

	@include bp("> 800px") {
		&::before {
			content: "";
			background: transparent url(/dist/images/logo.svg) no-repeat 0 0 / 100%;
			width: 45%;
			height: 0;
			padding-bottom: 50%;
			position: absolute;
			top: 20px;
			left: 5%;
			opacity: .3;
			transform: rotate(-11deg);
			pointer-events: none;
		}
	}
}

.flair {
	position: absolute;
	display: none;

	@include bp("> 1800px") {
		display: block;

		&.-pepper {
			left: -4%;
			top: -4%;
		}
		
		&.-garlic {
			left: 93%;
			bottom: 0;
		}

		&.-steak {
			left: -10%;
			top: 75%;
		}
		
		&.-basil {
			left: 93%;
			top: -2%;
			z-index: 3;
		}
		
		&.-dill {
			left: -6%;
			top: 82%;
		}
		
		&.-chillipepper {
			right: -10%;
			top: 52%;
		}
	}
}

.error-404 {
	padding: 0 5vw;

	&__flair {
		font-size: 22vw;
	}
}
