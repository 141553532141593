

.form {
	padding: 2em 0;
	margin: 0 0 2em 0;

	&__fields {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: -1.25rem;
		margin-right: -1.25rem;

		> :not(.form__section) {
			width: 100%;
			padding-left: 1.25rem;
			padding-right: 1.25rem;
		}
	}

	&__section {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: flex-start;
		flex-basis: 50%;
		padding: 0 1.25rem;
		min-width: 18em;
		margin-bottom: 1.1em;

		&.-full {
			width: 100%;
			flex-basis: 100%;
		}

		&.-to-end {
			justify-content: flex-end;
		}

		&.-to-center,
		&.-to-right {
			display: flex;
			flex-wrap: wrap;
			flex-basis: 1;
			width: 100%;
		}

		&.-to-center {
			align-items: center;
			justify-content: center;
			text-align: center;
		}

		&.-to-right {
			align-items: flex-end;
			justify-content: flex-end;
			text-align: right;
		}

		.label {
			margin-bottom: .6em;
		}

		.button {
			@include quantity-query(min 2) {
				margin-bottom: .5em;
			}

			&:not(:first-child):not(.-block) {
				margin-left: .5em;
			}
		}
	}

	&__label {
		display: block;
		margin-bottom: .4em;
		cursor: default;
	}

	&__file-value {
		color: inherit;
		opacity: .8;
	}

	&__fieldset {
		padding: .85em 1.5em .625em;
		border: 1px $form-item-border-color solid;
		border-radius: $form-item-border-radius;
	}

	&__legend {
		padding: 0 .5em;
		font-size: 1.35rem;
		font-weight: bold;
	}
}
