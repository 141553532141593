.hsr {
	position: absolute;
	left: -9999rem;
	overflow: hidden;
	width: 0;
	height: 0;
	outline: 0;
}

.nls {
	list-style-type: none;
}

.centered {
	max-width: $max-width;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	@include bp("> 600px") {
		width: 92%;
	}

	&.-padded {
		padding-left: 5%;
		padding-right: 5%;
	}

	&.-padded-left {
		padding-left: 5%;
	}

	&.-padded-right {
		padding-right: 5%;
	}
}
