
.main-nav {
	flex-shrink: 0;
	color: #ffffff;
	padding: 1em 0;

	@include bp("> 1024px") {
		flex-grow: 1;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include bp("> 400px") {
			flex-direction: row;
		}

		@include bp("> 1024px") {
			margin: 0 0 0 20px;
		}
		
		@include bp("> 1024px") {
			margin: 0 20px;
		}
	}

	&__item {
		display: inline-block;
		margin: 10px 14px;
		text-align: center;

		@include bp("> 400px") {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		@include bp("> 800px") {
			margin: 10px 24px;
		}
	}

	&__link {
		padding: 0;

		@include link(&) {
			color: #ffffff;
			text-decoration: none;
		}

		@include link-over(&) {
			color: $color-brand;
		}

		&.-active {
			text-decoration: none;
			cursor: default;
			pointer-events: none;
			color: $color-brand;
		}
	}

	/* autoprefixer: off */
	.fixed-menu & {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 10;
		animation: slide-from-top .3s ease-out;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		background-color: #181818;
	}
}
