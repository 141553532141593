
/*==============================
	FONTS
==============================*/

$font-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
$font-body: Roboto, $font-system-stack;
$font-headings: "Roboto Slab", Poppins, Futura, Montserrat, sans-serif;


/*==============================
	COLORS
==============================*/

$color-base: #242424;
$color-brand: #c80b0b;
$color-complementary: #090909;
$color-secondary: #efefef;
$color-tertiary: #28C76F;
$color-light: #f6f2ef;
$color-dark: #383433;


/*==============================
	SIZES
==============================*/

$max-width: 1616px;
$grid-spacing: 2.25vw;


/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: #6b9aeb;
$form-active-text: #ffffff;

$form-item-standard-size: 42px;
$form-item-xl-size: 54px;
$form-item-border-color: #cccccc;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, .12);
$form-icon-color: #cccccc;


/*==============================
	BUTTONS
==============================*/

$button-radius: 4px;
$button-bg: $color-brand;
$button-fg: $color-light;
$button-primary-bg: $color-brand;
$button-primary-fg: $color-light;
